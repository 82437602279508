import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

const AlertMessage = ({ variant, header, message, showMessage, handleShow }) => {
    handleShow(showMessage);

    if (showMessage) {
        return (
            <Alert
                style={{ zIndex: 2 }}
                variant={ variant !== undefined ? variant : "danger" }
                onClose={() => handleShow(false)} dismissible>

                { header !== undefined ?
                    <Alert.Heading>{ header }</Alert.Heading>
                : null }

                <p className="m-0">
                    { message !== undefined ? message : "" }
                </p>
            </Alert>
        );
    }
}

export default AlertMessage;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProductPresentation from '../components/ProductPresentation';
import ProductGrids from '../components/ProductGrids';
import config from '../config/config.json'
import axios from 'axios';
import Loading from '../components/Loading';
import cookie from 'js-cookie';

const InnerProduct = () => {
	console.log(window.location.href.split('/'));
	let tradeType = window.location.href.split('/')[4];
	let productId = window.location.href.split('/')[5];

	//console.log(productId);

	const [product, setProduct] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const [suggestions, setSuggestions] = useState(null);
	const [familyData, setFamilyData] = useState(null);

	const location = useLocation();

	const [selectedProduct, setSelectedProduct] = useState(parseInt(productId));


	useEffect(() => {
		console.log(location);
		let splitted = location.pathname.split('/');
		setSelectedProduct(splitted[3]);
	}, [window.location.href]);

	useEffect(() => {
		setIsLoading(true)
		let ignore = false;

		function fetchData() {
			axios.get(config.SERVER_URL + config.GET_PRODUCT + selectedProduct)
				.then((response) => {
					// handle success
					if(!ignore) {
						setProduct(response.data.data)
					}

				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					//Always Executed
					setIsLoading(false);
			});
		}


		fetchData();

		return () => {
			ignore = true;
		}
		}, [selectedProduct]);

	useEffect(() => {
		if(product) {
			setLoadingSuggestions(true);
			let ignore = false;

			function fetchSuggestions() {
				axios.get(config.SERVER_URL + config.GET_ALL_PRODUCTS_GROUPS)
				.then((response) => {
					if (!ignore) {
						let productsArray = [];

						response.data.data.map((group) => {
							group.familias?.filter(family => family.id === product?.familiaId).map((family) => {
									if(tradeType === 'cn') {
										family.productos?.filter(p => p.comercioNac === true && p.id != product.id)
										.sort(()=> Math.random() - Math.random()).slice(0,4).map(mapProduct => {
												productsArray.push(mapProduct);
										})
									}
									else {
										family.productos?.filter(p => p.comercioExt === true && p.id != product.id)
										.sort(()=> Math.random() - Math.random()).slice(0,4).map(mapProduct => {
												productsArray.push(mapProduct);
										})
									}

								setFamilyData(family);
							})
						})
						setSuggestions(productsArray);
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					setLoadingSuggestions(false);
				})
			}

			fetchSuggestions();
			setTitle();

			return () => {
				ignore = true;
			}
		}

	}, [product]);

	const setTitle = () => {
		if(product) {
			if(cookie.get('i18next') === 'en') {
					document.title = product.nameEn + " - Selecta S.A";
			} else {
					document.title = product.nameEs + " - Selecta S.A";
			}
		}
	}

	const getImageProduct = () => {
		let imageUrl = "";
			if(product.files?.length > 0) {
				product.files?.map(file => {
					if(file.fileType === "MainImage") {
						imageUrl = "https://dev-back-selecta.creadores.io/uploads/"+file.uploadResult.storedFileName;
					}
				})
			}
		return imageUrl;
	}

	return (
		<>
			{ !isLoading ?
				<div className="bodyContainer bread-content">
					<div className="innerProductWrapper">
						<div data-aos="fade-right" className="leftInnerProductInformation">
							{/*<ProductCarousel />*/}
							<img src={ getImageProduct() } className="d-block w-100" />
						</div>

						<div className="rightInnerProductInformation">
							<h1 data-aos="fade-left">{ cookie.get('i18next') == 'en' ? product.nameEn : product.nameEs }</h1>
							<p data-aos="fade-left">{ product.sku } <span>{ product.barCode }</span></p>

							{ cookie.get('i18next') == 'en' ?
								<p data-aos="fade-left" className="inner-product-text">
									{
										product.detailsEn?.longDescription
									}
								</p>
								:
								<p data-aos="fade-left" className="inner-product-text">
									{
										product.detailsEs?.longDescription
									}
							</p>
							}

							{ cookie.get('i18next') == 'en' ?
								<p data-aos="fade-left" className="inner-product-text">
									{ product.detailsEn?.composition }
								</p>
								:
								<p data-aos="fade-left" className="inner-product-text">
									{ product.detailsEs?.composition }
							</p>
							}

							{/*
							<NavLink className="mt-3 mb-4 d-inline-block buttonNormal buttonOrange nutritionalInformation">
								Información nutricional
							</NavLink>
							*/}

							{ product.presentaciones?.length > 0 ?
								<div data-aos="fade-left" className="mt-5 productPresentations">
									<h2 data-aos="fade-left" className="title-inner-product">
										{ cookie.get('i18next') == 'en' ? "PRESENTATIONS": "PRESENTACIONES" }
									</h2>
									<ProductPresentation presentations={ product.presentaciones } />
								</div>
							: null }
						</div>
					</div>

					{/*
					<div className="productCertifications">
						<h2>CERTIFICACIONES</h2>
						<ProductCertification />
					</div>
					*/}

					<div className="suggestedProducts">
						<h2 data-aos="fade-up">{ cookie.get('i18next') === 'en' ? "SUGGESTIONS": "SUGERENCIAS" }</h2>

						{ !loadingSuggestions ?
							suggestions && (
								<div className="productGridWrapper">
									{ tradeType === "cn" ?
										suggestions
										.map((product, index) => (
											<ProductGrids
												key={index+"ps"+product.id}
												product={ product }
												tradeType={ tradeType }
												familyName={ cookie.get('i18next') === 'en' ? familyData.nameEn : familyData.nameEs } />
										))
										:
										suggestions
										.map((product, index) => (
											<ProductGrids
												key={index+"ps"+product.id}
												product={ product }
												tradeType={ tradeType }
												familyName={ cookie.get('i18next') === 'en' ? familyData.nameEn : familyData.nameEs } />
									))
									}
								</div>
							)
							: <Loading />
						}
					</div>

				</div>
				:
				<Loading />
			}
		</>
	)
}

export default InnerProduct;

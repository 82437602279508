import React, { useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import TopVideo from '../videos/grupo_selecta_en.mp4'
import Lightbox from "react-18-image-lightbox";
import leftLeaf from '../images/leaf_group_left.png';
import rightLeaf from '../images/leaf_group_right.png';
import "react-18-image-lightbox/style.css";
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';
import { useMediaQuery } from 'react-responsive';

const SelectaGroup = () => {
    const { t } = useTranslation();
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
    document.title = cookie.get('i18next') === 'en' ? "Selecta Group - Selecta S.A" : "Grupo Selecta - Selecta S.A";

    const slider = [
        require('../images/slider1.png'),
        require('../images/slider2.png')
    ]

    const images = [
        require('../images/historia_1.jpg'),
        require('../images/historia_2.jpg'),
        require('../images/historia_3.jpg'),
        require('../images/selecta_paquetes.png')
    ]

    const [state, setState] = useState({
        photoIndex: 0,
        isOpen: false
    })

    return (
        <>
            { isMobileToTablet ?
                <img src={
                cookie.get('i18next') == 'en' ?
                    require('../images/grupo_selecta_title_mobile_en.png') :
                    require('../images/grupo_selecta_title_mobile.png')
                } className="imageSection" />
                :
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/grupo_selecta_title_en.png') :
                        require('../images/grupo_selecta_title.png')
                } className="imageSection" />
            }

            <div className="bodyContainer mt-0">
                <div className="index-content-wrapper mb-0">

                    <img data-aos="fade-right" className="big-leaf-left" src={ leftLeaf } />
                    <img data-aos="fade-left" className="big-leaf-right" src={rightLeaf } />

                    <h2 data-aos="fade-in" className="greenTitle text-center mb-6">{ t('sg_letter_title') }</h2>
                    <div className="index-grid-content bread-content two-block-wrapper align-items-start">
                        <div data-aos="fade-in" className="grey-light-text text-in-blocks">
                            <p>{ t('sg_letter_p1') }</p>
                            <p>{ t('sg_letter_p2') }</p>
                            <p>{ t('sg_letter_p3') }</p>
                            <p>{ t('sg_letter_p4') }</p>
                        </div>

                        <div data-aos="fade-in" className="grey-light-text text-in-blocks">
                            <p>{ t('sg_letter_p5') }</p>
                            <p>{ t('sg_letter_p6') }</p>
                            <p>{ t('sg_letter_p7') }</p>
                            <p>{ t('sg_letter_p8') }</p>
                        </div>
                    </div>
                </div>

                <img data-aos="zoom-in" src={ require('../images/grupo_selecta_image_1.png') } className="w-100 d-block mt-6" />

                <div className="index-grid-content bread-content two-block-wrapper align-items-start mt-7">
                    <div data-aos="fade-in" className="grey-light-text text-in-blocks">
                        <h2 className="selecta-group-history-title">
                            { cookie.get('i18next') === 'en' ?
                                "Our Story"
                                :
                                "Nuestra Historia"
                            }
                        </h2>
                        <p className="selecta-group-history-text">{ t('sg_history_p1') }</p>
                        <img data-aos="zoom-in" src={ require('../images/grupo_selecta_hist_2.png') } className="w-100 d-block mt-4" />
                    </div>

                    <div data-aos="fade-in" className="grey-light-text text-in-blocks">
                        <img data-aos="zoom-in" src={ require('../images/grupo_selecta_hist_1.png') } className="w-100 d-block mb-4" />
                        <p className="selecta-group-history-text">{ t('sg_history_p2') }</p>
                        <p className="selecta-group-history-text">{ t('sg_history_p3') }</p>
                    </div>
                </div>


                <div className="index-content-wrapper mb-0 overflow-initial">
                    <div className="index-grid-content bread-wide-content two-block-wrapper overflow-initial align-items-center mb-7">
                        <div data-aos="fade-up" className="left-why-selecta-block">
                            <h2 className="greenTitle mb-4">{ t('sg_why_selecta_title') }</h2>
                            <p className="selecta-group-history-text">
                                { t('sg_why_selecta_text') }
                            </p>
                        </div>
                        <img data-aos="fade-down" src={ require('../images/why_yerba_mate.png') } className="w-100 d-block" />
                    </div>
                </div>

                <div data-aos="zoom-in" className="index-content-wrapper mt-6 mb-6">
                    <img className="top-bg-design z-1" src={ require('../images/corte_top.png') } />
                    <img className="bottom-bg-design z-1" src={ require('../images/corte_bottom.png') } />
                    <div className="block-spacing video-block mt-0">
                        <ReactPlayer
                            className='react-player'
                            url={[TopVideo,'mp4']}
                            muted={false}
                            width="100%"
                            height="100%"
                            config= {{
                                file: {
                                    attributes: {
                                        controls: true,
                                        autopictureinpicture: true,
                                        poster: require('../videos/selecta_group_poster.png'),
                                    }
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="index-grid-content bread-content mb-6 align-items-center">
                    <div className="mision-vision-wrapper">
                        <div className="block-divider">
                            <div data-aos="fade-up">
                                <img src={
                                    cookie.get('i18next') == 'en' ?
                                        require('../images/english/vision.png') :
                                        require('../images/vision.png')
                                } className="img-mision" />
                                <p className="grey-text text-center">{ t('sg_vision') }</p>
                            </div>

                            <div data-aos="fade-up">
                                <img src={
                                    cookie.get('i18next') == 'en' ?
                                        require('../images/english/mission.png') :
                                        require('../images/mision.png')
                                } className="img-mision" />
                                <p className="grey-text text-center">{ t('sg_mission') }</p>
                            </div>
                        </div>

                        <div data-aos="fade-up" className="align-center">
                            <img src={
                                cookie.get('i18next') == 'en' ?
                                    require('../images/english/values.png') :
                                    require('../images/valores.png')
                            } className="img-valores" />

                            <ul data-aos="fade-in" className="valores-list">
                                <li>
                                    <p>{ t('sg_value_1_title') }</p>
                                    <p>{ t('sg_value_1_text') }</p>
                                </li>
                                <li>
                                    <p>{ t('sg_value_2_title') }</p>
                                    <p>{ t('sg_value_2_text') }</p>
                                </li>
                                <li>
                                    <p>{ t('sg_value_3_title') }</p>
                                    <p>{ t('sg_value_3_text') }</p>
                                </li>
                                <li>
                                    <p>{ t('sg_value_4_title') }</p>
                                    <p>{ t('sg_value_4_text') }</p>
                                </li>
                                <li>
                                    <p>{ t('sg_value_5_title') }</p>
                                    <p>{ t('sg_value_5_text') }</p>
                                </li>
                                <li>
                                    <p>{ t('sg_value_6_title') }</p>
                                    <p>{ t('sg_value_6_text') }</p>
                                </li>
                                <li>
                                    <p>{ t('sg_value_7_title') }</p>
                                    <p>{ t('sg_value_7_text') }</p>
                                </li>
                                <li>
                                    <p>{ t('sg_value_8_title') }</p>
                                    <p>{ t('sg_value_8_text') }</p>
                                </li>
                            </ul>
                            <div data-aos="fade-up" className="image-selecta-group-bottom">
                                <img src={ require('../images/imagen_mundo.png') } className="w-100 d-block mt-2" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {state.isOpen && (
                <Lightbox
                    mainSrc={images[state.photoIndex]}
                    nextSrc={images[(state.photoIndex + 1) % images.length]}
                    prevSrc={images[(state.photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                        setState(prev => ({ ...prev,
                            photoIndex: (state.photoIndex + images.length - 1) % images.length
                        }))
                    }
                    onMoveNextRequest={() =>
                        setState(prev => ({ ...prev,
                            photoIndex: (state.photoIndex + 1) % images.length
                        }))
                    }
                />
            )}
        </>
    )
}

export default SelectaGroup;

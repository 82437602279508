import React, { useState, useEffect } from 'react';
import RecipeGrids from '../components/RecipeGrids';
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import Loading from '../components/Loading';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import AlertToastMessages from "../components/AlertToastMessages";

const Receta = () => {
    const { t } = useTranslation();
	const recipeId = window.location.href.split('/')[4];
	const [loading, setLoading] = useState(false);
	const [recipe, setRecipe] = useState(null);

	const initialState = {
		name: { data: "", error: '' },
        comment: { data: "", error: "" }
	}

	const [inputs, setInputs] = useState(initialState);

	const [showToast, setShowToast] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [variant, setVariant] = useState(null);
	const [message, setMessage] = useState(null);
	const [alertShow, setAlertShow] = useState(false);
	const [checking, setChecking] = useState(false);
	let captcha;

	const getDate = (date) => {
		let newDate = new Date(date);
		let options = { weekday: "long", day: "numeric", year: "numeric", month: "long" };
		if(cookie.get('i18next') == 'en') {
			return new Intl.DateTimeFormat("en-us", options).format(newDate);
		}
		else {
			return new Intl.DateTimeFormat("es-AR", options).format(newDate);
		}
	}

	const [captchaValidation, setCaptchaValidation] = useState(false);
	const [captchaError, setCaptchaError] = useState("");

	const resetCaptcha = () => {
		captcha.reset();
	}

	const setCaptchaRef = (ref) => {
		if(ref) {
			return captcha = ref;
		}
	}

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setInputs({ ...inputs, [name] : { data: value }});
	}

	function onChangeRecaptcha(value) {
		if(value === null) {
			setCaptchaValidation(false);
		}
		else {
			setCaptchaValidation(true);
			setCaptchaError("");
		}
	}

	const validateFields = () => {
		let ready = false;
		let errorFound = 0;

		if(!captchaValidation) {
			setCaptchaError(cookie.get('i18next') === 'en' ? "Captcha is required" : "Debes pasar validación del captcha.");
			errorFound++;
			setChecking(false);
		}
		if(!inputs.name.data) {
			setInputs(prevState => { return { ...prevState, name: {
				error: cookie.get('i18next') === 'en' ? "Name field is required" : "Debes completar el campo de Nombre"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.comment.data) {
			setInputs(prevState => { return { ...prevState, comment: {
				error: cookie.get('i18next') === 'en' ? "Comment field is required" : "Debes completar el campo de Comentario"
			}}});
			errorFound++;
			setChecking(false);
		}


		if(errorFound <= 0) {
			ready = true;
		}
		return ready;
	}


	const handleSubmit = (e) => {
		let eventForm = e;
		eventForm.preventDefault();
		let ignore = false;
		setChecking(true);

		if(validateFields()) {
			axios.post(config.SERVER_URL + config.POST_RECIPE_COMMENT, {
                recipeId: recipeId,
				name: inputs.name.data,
                description: inputs.comment.data
				})
				.then((response) => {
					if (!ignore) {
						if(response.data.status === "error") {
							console.log(response.data);
							setVariant("danger");
							setMessage(response.data.message);
							setAlertShow(true);
							setChecking(false);
						}

						if(response.data.status === "success") {
							console.log(response.data);
							setShowToast(true);
							setShowMessage(true);
							setChecking(false);
							clearFormReset();
							resetCaptcha();
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	function clearFormReset() {
		setInputs(initialState);
	}

	useEffect(() => {
		setLoading(true);
		let ignore = false;

		async function getSingleRecipe() {
			await axios
			.get(config.SERVER_URL + config.GET_SINGLE_RECIPES + recipeId)
			.then((response) => {
				// handle success
				console.log(response.data);
				if(!ignore) {
					setRecipe(response.data.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setLoading(false);
			})
            .finally(() => {
                document.title = cookie.get('i18next') === 'en' ? 
                    `${recipe?.response.titleEn} - Selecta S.A.`
                    :
                    `${recipe?.response.titleEs} - Selecta S.A.`
            })
		}

		getSingleRecipe();

		return () => {
			ignore = true;
		}
	}, []);

    useEffect(() => {
        if(recipe) {
            document.title = cookie.get('i18next') === 'en' ? 
                `${recipe?.response.titleEn} - Selecta S.A.` 
                : 
                `${recipe?.response.titleEs} - Selecta S.A.` 
        }
    },[recipe]);

	return (
		<>
			{ !loading ?
                recipe?.response && (
                    <>
                        <div className="bodyContainer bread-content mt-7 mb-7">
                            <h2 data-aos="fade-down" className="greenTitle mb-4 text-center">{ cookie.get('i18next') == 'en' ? recipe?.response.titleEn : recipe?.response.titleEs }</h2>
                            <img data-aos="fade-down" className="d-block w-100 image-inner-content mb-4"
                                src={ `https://dev-back-selecta.creadores.io/uploads/${recipe?.response.image?.storedFileName}` }
                            />

                            <div
                                data-aos="fade-up"
                                className="inner-news-body-container"
                                dangerouslySetInnerHTML={{
                                    __html: cookie.get('i18next') === 'en' ? recipe?.response.bodyEn : recipe?.response.bodyEs
                            }} />

                        </div>

                        <div className="mt-6 bg-other-recipe">
                            <h2 className="other-recipe-title text-center color-white mt-2 mb-5">
                                { cookie.get('i18next') === 'en' ?
                                    "OTHER RECIPES"
                                    :
                                    "OTRAS RECETAS"
                                }
                            </h2>
                            <div className="other-recipe-grid-wrapper bread-content">
                                { recipe?.recipes.map((recipe) => (
                                    <RecipeGrids key={recipe.id} recipe={recipe} />
                                ))}
                            </div>
                        </div>
                        <AlertToastMessages show={showToast} setShow={setShowToast} />

                        <Alert
                            className="alert-block-wrapper mt-5"
                            show={showMessage}
                            onClose={ () => setShowMessage(false) }
                            dismissible
                            variant="success">
                            <Alert.Heading>
                                { cookie.get('i18next') === 'en' ?
                                    "Thank you very much for your feedback!" :
                                    "Muchas gracias por dejarnos tu comentario!"
                                }
                            </Alert.Heading>
                            <p>
                                { cookie.get('i18next') === 'en' ?
                                    "We hope you have enjoyed our Selecta Recipe"
                                    :
                                    "Esperamos que hayas disfrutado de nuestra receta Selecta"
                                }
                            </p>
                        </Alert>

                        <div className="bread-content mt-5">
                            <h2 className="recipe-message-title">
                                { cookie.get('i18next') === 'en' ?
                                    "Did you like the recipe? Give us your feedback"
                                    :
                                    "¿Te gustó la receta? Dejanos tu comentario"
                                }
                            </h2>
                            <form
                                onSubmit={(e) => { handleSubmit(e) }}
                                className="formContact mb-7">

                                <div className="row mb-4">
									<label htmlFor="userName" className="form-label">
										{ cookie.get('i18next') === 'en' ? "Name *" : "Nombre *" }
									</label>
									<input
										type="text"
										className="form-control"
										name="name"
										id="name"
										value={ inputs.name.data || "" }
										onChange={ handleInputChange }
									/>
									<span className="span-error-message">{ inputs.name.error !== '' ? inputs.name.error : '' }</span>
                                </div>

							<div className="row mb-4">
								<div className="col">
									<label htmlFor="body" className="form-label">
										{ cookie.get('i18next') === 'en' ? "Message *" : "Mensaje *" }
									</label>
									<textarea
										className="form-control"
										rows="10"
										name="comment"
										value={ inputs.comment.data || "" }
										onChange={ handleInputChange }
									></textarea>
									<span className="span-error-message">{ inputs.comment.error !== '' ? inputs.comment.error : '' }</span>
								</div>
							</div>

							<div style={{ marginTop: '1.3rem', marginBottom: '1.3rem' }}>
								<ReCAPTCHA
								ref={ (r) => setCaptchaRef(r) }
									sitekey="6LctQ20kAAAAADXjhXI7k0EFbbAWmnPeGq_zljRu"
									onChange={onChangeRecaptcha}
								/>
								<span className="span-error-message">{ captchaError !== '' ? captchaError : '' }</span>
							</div>

							<button
								type="submit"
								className="btn btn-form-submit"
								disabled={ !checking ? false : true }>

								{ !checking ?
									cookie.get('i18next') === 'en' ? "Send message" : "Enviar mensaje"
									:
									<>
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
											style={{ marginRight: "10px" }}
										/>
										{ cookie.get('i18next') === 'en' ? "Verifying..." : " Verificando..."  }
								</>
								}
							</button>
                            </form>
                        </div>
                    </>
                )
				:
				<Loading />
			}
		</>
	)
}

export default Receta;

import React from 'react';
//import sectionImage from '../images/comercio_exterior.png'
import SliderImages from '../components/SliderImages';
import cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import SplideImages from '../components/SplideImages';
import { useMediaQuery } from 'react-responsive';

const ComercioExterior = () => {
	document.title = cookie.get('i18next') == 'en' ? "Foreign trade - Selecta S.A" : "Comercio Exterior - Selecta S.A";
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const { t } = useTranslation();

	const slider_comercio_exterior = [
		require("../images/comercio_exterior/slider_1.jpg"),
		require("../images/comercio_exterior/slider_2.jpg"),
		require("../images/comercio_exterior/slider_3.jpg"),
	]

	return (
		<>
            { isMobileToTablet ?
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/comercio_exterior_title_mobile_en.png') :
                        require('../images/comercio_exterior_title_mobile.png')
                    } className="imageSection" />
                :
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/comercio_exterior_title_en.png') :
                        require('../images/comercio_exterior_title.png')
                    } className="imageSection" />
            }

			<div className="bodyContainer mt-0">
				<div className="perfil-pais-block-divider block-light-spacing mt-0">
					<div data-aos="fade-right" className='ml-4'>
						<h2 className="greenTitle mb-3">{ t('ft_profile_title') }</h2>
						<div className="perfil-pais-text">
							<p>
								{ t('ft_profile_p1') }
							</p>

							<p>
								{ t('ft_profile_p2') }
							</p>
						</div>
					</div>
					<div data-aos="fade-left">
						<img className="py-map-comercio w100 d-block" src={ require('../images/comercio_exterior/py_map.png') } />
					</div>
				</div>

				<div className="background-orange-content comercio-exterior-bg">
                    <img className="top-bg-design z-1" src={ require('../images/corte_top.png') } />
                    <img className="bottom-bg-design z-1" src={ require('../images/corte_bottom.png') } />

                    <div className="index-grid-content bread-content inner-orange-bg-content">
                        <img data-aos="fade-down" className="d-block img-global-map" src={ require('../images/comercio_exterior/global_map.png') } />

                        <div className="two-block-wrapper mb-0 align-items-start">
                            <div data-aos="fade-right" className="text-white">
                                <p>
                                { t('ft_profile_ig_p1') }
                                </p>
                                <p>
                                { t('ft_profile_ig_p2') }
                                </p>
                            </div>

                            <div data-aos="fade-left" className="text-white">
                                <p>
                                { t('ft_profile_ig_p3') }
                                </p>
                            </div>
                        </div>
                    </div>
				</div>

                <div data-aos="fade-up" className="bread-content mt-5">
                    <SplideImages images={slider_comercio_exterior} label="Yerba Mate" />
                </div>
			</div>
		</>
	)
}

export default ComercioExterior;

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import config from '../config/config.json';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import i18next from 'i18next';
import cookie from 'js-cookie';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

// Contains the value and text for the options
const languages = [
    {
        code: 'es',
        name: 'Español',
        country_code: 'py',
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    }
];

const MenuDesktop = () => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        groups: []
    });

    const CheckFlag = () => {
        if(cookie.get('i18next') == 'en') {
            return <span className="flag-icon flag-icon-gb"></span>;
        }
        else if(cookie.get('i18next') == 'es') {
            return <span className="flag-icon flag-icon-py"></span>;
        }
        else {
            return <GlobeIcon />;
        }
    }

    const handleLogout = () => {
        if(cookie.get('ustatus')) {
            cookie.set('ustatus', false);
            cookie.set("token", "");
            cookie.set("uid", "");
            cookie.set("name", "");
            cookie.set("lastName", "");
            cookie.set("email", "");
        }
        window.location.replace('/');
    }

    useEffect(() => {
        let ignore = false;

        async function fetchData() {
            const result = await axios(config.SERVER_URL + config.GET_ALL_GROUPS);
            if (!ignore) setState(prev => ({ ...prev, groups: result.data.data}));
        }

        fetchData();
        return () => { ignore = true; }
    }, []);

    function PascalCase(string) {
        const lowerCase = string.toLowerCase();
        const finalSentence = lowerCase.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return finalSentence
    }

    const GlobeIcon = ({ width = 16, height = 16 }) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="currentColor"
            className="bi bi-globe"
            viewBox="0 0 16 16"
        >
            <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
        </svg>
    )

    return (
        <>
            <ul className="buttonsList">
                <li>
                    <NavLink exact to="#">{ t('menu_about_us') }
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-down-fill menuIcon" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                        <div className="subMenuContent">
                            <ul>
                                <li>
                                    <NavLink to="/nosotros/grupo-selecta">{ t('menu_selecta_group') }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/nosotros/empresa">{ t('menu_business') }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/nosotros/red-de-distribucion">{ t('menu_network_distribution') }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/social">{ t('menu_social') }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/noticias">{ t('menu_news') }
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/yerba-mate">Yerba mate</NavLink>
                </li>
                <li>
                    <NavLink exact to="/turismo">{ t('menu_tourism') }</NavLink>
                </li>
                <li>
                    <NavLink exact to="#">{ t('menu_catalog') }
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-down-fill menuIcon" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                        <div className="subMenuContent">
                            <ul>
                                <li>
                                    <NavLink to="/catalogos/comercio-exterior" className="displaySubSubMenu">{ t('menu_foreign_trade') }
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                        { state.groups.length > 0 ?
                                            <div className="subSubMenuContent">
                                                { state.groups.map((group, i) => (
                                                    group.comercioExt ?
                                                        <NavLink id={i} to={`/productos/ft/${group.id}/${cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs}`} className="displaySubSubSubMenu">
                                                            { PascalCase(cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs) }
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                            </svg>

                                                            { group.familias.length > 0 ?
                                                                <div className="subSubSubMenuContent">
                                                                    { group.familias.map((familia,j) => (
                                                                        familia.comercioExt ?
                                                                            (<NavLink id={j} to={`/productos/ft/${group.id}/${cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs}/${familia.id}`}>
                                                                                { PascalCase(cookie.get('i18next') == 'en' ? familia.nameEn : familia.nameEs) }
                                                                            </NavLink>)
                                                                            : null
                                                                    ))}
                                                                </div>
                                                                : null }
                                                        </NavLink>
                                                        : null
                                                ))}
                                            </div>
                                            : null }
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/catalogos/nacional" className="displaySubSubMenu">{ t('menu_national') }
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                        { state.groups.length > 0 ?
                                            <div className="subSubMenuContent">
                                                { state.groups.map((group, i) => (
                                                    group.comercioNac ?
                                                        <NavLink id={i} to={`/productos/cn/${group.id}/${cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs}`} className="displaySubSubSubMenu">
                                                            { PascalCase(cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs) }
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                            </svg>

                                                            { group.familias.length > 0 ?
                                                                <div className="subSubSubMenuContent">
                                                                    { group.familias.map((familia,j) => (
                                                                        familia.comercioNac ?
                                                                            (<NavLink id={j} to={`/productos/cn/${group.id}/${cookie.get('i18next') == 'en' ? group.nameEn : group.nameEs}/${familia.id}`}>
                                                                                { PascalCase(cookie.get('i18next') == 'en' ? familia.nameEn : familia.nameEs) }
                                                                            </NavLink>)
                                                                            : null
                                                                    ))}
                                                                </div>
                                                                : null }
                                                        </NavLink>
                                                        : null
                                                ))}
                                            </div>
                                            : null }
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </NavLink>
                </li>

                <li>
                    <NavLink exact to="#">{ t('menu_foreign_trade') }
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-down-fill menuIcon" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                        <div className="subMenuContent">
                            <ul>
                                <li>
                                    <NavLink to="/comercio-exterior">{ t('menu_foreign_trade') }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/distribuidores">{ t('menu_distributors') }
                                    </NavLink>
                                </li>
                                { false && (
                                    <li>
                                        <NavLink to="/noticias/comercio-exterior">{ t('menu_foreign_trade_news') }
                                        </NavLink>
                                    </li>
                                )}

                                {/*
                                <li>
                                    <NavLink to="#">{ t('menu_foreign_trade_contact') }
                                    </NavLink>
                                </li>
                                */}
                            </ul>
                        </div>
                    </NavLink>
                </li>
            </ul>

            <ul className="optionsList">
                { cookie.get('ustatus') === 'true' ?
                    <li>
                        <div class="dropdown">
                            <button
                                class="btn btn-user-menu border border-0 dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                { cookie.get('i18next') == 'en' ?
                                    `Welcome, ${cookie.get('name')}`
                                    :
                                    `Bienvenido/a, ${cookie.get('name')}`
                                }
                            </button>

                            <ul class="dropdown-menu dropdown-user-menu">
                                <li>
                                    <NavLink to="/perfil">
                                        <PersonIcon />
                                        { cookie.get('i18next') == 'en' ? "My account" : "Mi perfil" }
                                    </NavLink>

                                </li>

                                <li>
                                    <button onClick={ handleLogout }>
                                        <LogoutIcon />
                                        { cookie.get('i18next') == 'en' ? "Logout" : "Cerrar sesión" }
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </li>
                    :
                    <>
                        <li>
                            <NavLink to="/iniciar-sesion" className="button buttonOptionMenu buttonOrange">{ t('menu_log_in') }</NavLink>
                        </li>
                        <li>
                            <NavLink to="/registrate" className="button buttonOptionMenu buttonOrangeReverse">{ t('menu_register') }</NavLink>
                        </li>
                    </>
                }


                <li>
                    <div class="dropdown">
                        <button class="btn btn-link" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <CheckFlag />
                        </button>

                        <ul class="dropdown-menu">
                            { languages.map(({ code, name, country_code }) => (
                                <li key={ country_code }>
                                    <button class="dropdown-item" onClick={ () => { i18next.changeLanguage(code) }}>
                                        <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
                                        { name }
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
            </ul>
        </>
    )
}

export default MenuDesktop;

import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import cookie from 'js-cookie';

const NewsGrid = ({ news, type }) => {
    return (
        <>
            { news && (
                <div data-aos="fade-down" className="news-grid-container">
                    { type == "cn" ?
                        news.filter(n => n.type === "Comercio Nacional").map((newsData,key) => (
                            <>
                                <NavLink className="link-img-news d-block mb-2" to={type == "cn" ? `/noticia/nacional/${newsData.id}` : `/noticia/comercio-exterior/${newsData.id}`}>
                                    <img
                                        className="w-100 d-block image-grid-news"
                                        src={ `https://dev-back-selecta.creadores.io/uploads/${newsData.image?.storedFileName}` }
                                    />
                                </NavLink>

                                <NavLink to={type == "cn" ? `/noticia/nacional/${newsData.id}` : `/noticia/comercio-exterior/${newsData.id}`}>
                                    <h3>{ cookie.get('i18next') == 'en' ? newsData.titleEn : newsData.titleEs }</h3>
                                </NavLink>

                                <p>{ cookie.get('i18next') == "en" ? newsData.descriptionEn : newsData.descriptionEs }</p>

                                <div className="show-more-recipe-wrapper">
                                    <Link
                                        to={type == "cn" ? `/noticia/nacional/${newsData.id}` : `/noticia/comercio-exterior/${newsData.id}`}
                                        className="newsBlockButton">Ver Noticia
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                        </svg>
                                    </Link>
                                </div>
                            </>
                        ))

                        :

                        news.filter(n => n.type === "Comercio Exterior").map((newsData,key) => (
                            <>
                                <NavLink className="link-img-news d-block mb-2" to={type == "cn" ? `/noticia/nacional/${newsData.id}` : `/noticia/comercio-exterior/${newsData.id}`}>
                                    <img
                                        className="w-100 d-block image-grid-news"
                                        src={ `https://dev-back-selecta.creadores.io/uploads/${newsData.image?.storedFileName}` }
                                    />
                                </NavLink>

                                <NavLink to={type == "cn" ? `/noticia/nacional/${newsData.id}` : `/noticia/comercio-exterior/${newsData.id}`}>
                                    <h3>{ cookie.get('i18next') == 'en' ? newsData.titleEn : newsData.titleEs }</h3>
                                </NavLink>

                                <p>{ cookie.get('i18next') == "en" ? newsData.descriptionEn : newsData.descriptionEs }</p>

                                <div className="show-more-recipe-wrapper">
                                    <Link
                                        to={type == "cn" ? `/noticia/nacional/${newsData.id}` : `/noticia/comercio-exterior/${newsData.id}`}
                                        className="newsBlockButton">Ver Noticia
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                        </svg>
                                    </Link>
                                </div>
                            </>
                        ))}
                </div>
            )}
        </>
    )
}

export default NewsGrid;

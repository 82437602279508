import React from "react";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';

function AlertToastMessages({ show, setShow, message }) {
	return (
		<>
			<ToastContainer className="p-3" position="top-end">
				<Toast
					onClose={() => setShow(false)}
					show={show}
					bg="success"
					delay={4000}
					autohide
					>

					<Toast.Body className="text-white">
						<div className="d-flex align-items-center justify-content-center">
							<DoneSharpIcon />
							<p className="m-0">
								{ message }
							</p>
						</div>
					</Toast.Body>
				</Toast>
			</ToastContainer>

		</>
	)
}

export default AlertToastMessages;

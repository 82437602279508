import React from 'react'
import cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import SplideImages from '../components/SplideImages';

const turismo_slider_images = [
	require('../images/turismo/slider1.jpg'),
	require('../images/turismo/slider2.jpg'),
	require('../images/turismo/slider3.jpg'),
	require('../images/turismo/slider4.jpg'),
	require('../images/turismo/mate_bar_1.jpg'),
	require('../images/turismo/mate_bar_2.jpg'),
	require('../images/turismo/mate_bar_3.jpg'),
	require('../images/turismo/mate_bar_4.jpg'),
	require('../images/turismo/mate_bar_5.jpg'),
	require('../images/turismo/mate_bar_6.jpg'),
	require('../images/turismo/mate_bar_7.jpg'),
	require('../images/turismo/mate_bar_8.jpg'),
	require('../images/turismo/mate_bar_9.jpg'),
	require('../images/turismo/mate_bar_10.jpg'),
	require('../images/turismo/mate_bar_11.jpg'),
	require('../images/turismo/mate_bar_12.jpg'),
	require('../images/turismo/reserva1.jpg'),
	require('../images/turismo/reserva2.jpg'),
	require('../images/turismo/reserva3.jpg'),
	require('../images/turismo/reserva4.jpg'),
	require('../images/turismo/reserva5.jpg'),
	require('../images/turismo/reserva6.jpg'),
	require('../images/turismo/reserva7.jpg'),
	require('../images/turismo/reserva8.jpg'),
	require('../images/turismo/reserva9.jpg'),
]

const selecta_mate_bar = require('../images/turismo/MATE_BAR_LOGO_1.png');

const hotel_slider_images = [
	require('../images/turismo/hotel1.jpg'),
	require('../images/turismo/hotel2.jpg'),
	require('../images/turismo/hotel3.jpg'),
	require('../images/turismo/hotel4.jpg'),
	require('../images/turismo/hotel5.jpg'),
	require('../images/turismo/hotel6.jpg'),
	require('../images/turismo/hotel7.jpg'),
	require('../images/turismo/hotel8.jpg'),
	require('../images/turismo/hotel9.jpg')
]

const Turismo = () => {
	const { t } = useTranslation();
	document.title = cookie.get('i18next') == 'en' ? "Tourism - Selecta S.A." : "Turismo - Selecta S.A.";
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });

	return (
		<>
            { isMobileToTablet ?
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/turismo_title_mobile_en.png') :
                        require('../images/turismo_title_mobile.png')
                } className="imageSection" />
                :
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/turismo_title_en.png') :
                        require('../images/turismo_title.png')
                } className="imageSection" />
            }

			<div className="bodyContainer mt-0">
                <div className="index-content-wrapper mb-0">
                    <div className="index-grid-content bread-content two-block-wrapper align-items-center">
                        <img data-aos="fade-right" className="d-block image-logo-reserva" src={ selecta_mate_bar } />
                        <div data-aos="fade-left" className="turismo-text-wrapper">
							<p className="grey-light-text">
								{ t('tour_mb_p1') }
							</p>
							<p className="grey-light-text">
								{ t('tour_mb_p2') }
							</p>
                        </div>
                    </div>
					<h4 data-aos="zoom-in" className="middle-title-matebar mobile-text-spacing text-center mt-5 grey-light-text m-auto">
                        { cookie.get('i18next') === 'en' ?
                            "BECOME AN EXPERT IN YERBA MATE!"
                            :
                            "¡PARA QUE TE CONVIERTAS EN UN EXPERTO YERBATERO!"
                        }
                        { false && (
						<div dangerouslySetInnerHTML={{ __html: t('tour_mb_p3') }} />
                        )}
					</h4>
					<p data-aos="fade-up" className="grey-light-text mobile-text-spacing text-center mt-4">
                        { cookie.get('i18next') === 'en' ?
                            <><b>Opening hours:</b> Thursdays to Tuesdays from 08:00 to 11:00 and 14:00 to 18:00.
                                <p>Contact us: <a href="https://wa.me/+595982700116" target="_blank" className="link-phone">+595 982 700116</a></p>
                            </>
                            :
                            <><b>Horario de atención:</b> Jueves a Martes de 08:00 a 11:30 y de 14:00 a 18:00 Hs.
                                <p>Contáctanos al: <a href="https://wa.me/+595982700116" target="_blank" className="link-phone">+595 982 700116</a></p>
                            </>
                        }
                        { false && (
						<div dangerouslySetInnerHTML={{ __html: t('tour_mb_p4') +" - "+ t('tour_mb_p5') }} />
                        )}
	 				</p>
                </div>

                <div data-aos="zoom-in" className="banner-text-wrapper bg-turismo-text">
                    <img className="top-bg-design" src={ require('../images/corte_top.png') } />
                    <img className="bottom-bg-design" src={ require('../images/corte_bottom.png') } />
                    <div data-aos="fade-up" className="text-center color-white" dangerouslySetInnerHTML={{ __html: t('tour_mb_p3') }} />
                </div>

                <div data-aos="fade-up" className="bread-content">
                    <SplideImages images={turismo_slider_images} label="Yerba mate Bar" />
                </div>

                <div className="index-content-wrapper mb-0 pt-5 bg-light-grey bg-complete-leaf">
                    <div className="index-grid-content bread-content two-block-wrapper align-items-center">
							<img
                            data-aos="fade-right"
								className="d-block image-logo-reserva"
								src={ require('../images/turismo/Logo_Reserva_de_Plantas_y_Animales_1.png') } />

                        <div data-aos="fade-left" className="turismo-text-wrapper">
                            { cookie.get('i18next') === 'en' ?
                                <h2 className="greenTitle mb-3">Experience nature at its best</h2>
                                :
                                <h2 className="greenTitle mb-3">¡Viví la naturaleza a flor de piel!</h2>
                            }
                            { false && (
                            <h2 className="greenTitle mb-2">{ t('tour_res_title') }</h2>
                            )}
                            <p className="grey-light-text">{ t('tour_res_p1') }</p>
                        </div>
                    </div>

                    <div className="index-grid-content mb-6 bread-content two-block-wrapper align-items-center">
                        <div data-aos="fade-right" className="turismo-text-wrapper">
                            <p className="grey-light-text">{ t('tour_res_p2') }</p>
                            <p className="grey-light-text">{ t('tour_res_p3') }</p>
							<p className="grey-light-text mt-5 text-bold mb-3">{ t('tour_res_p5') }</p>
							<div className="grey-light-text">
								<p className="m-0">{ t('tour_res_p6') }</p>
								<p className="m-0">{ t('tour_res_p7') }</p>
							</div>
                        </div>

                        <div data-aos="fade-left" className="reserva-icons-container">
                            { cookie.get('i18next') === 'en' ?
                                <h3 className="minor-h3-title">IT INCLUDES:</h3>
                                :
                                <h3 className="minor-h3-title">INCLUYE</h3>
                            }
                            <div className="reserva-icons-wrapper mb-3">
                                <div>
                                    <img src={ require('../images/turismo/reserva1.png') } />
                                    <p className="text-center grey-light-text">
                                        { cookie.get('i18next') === 'en' ? 'A bus to the reserve' : 'Bus hasta la reserva' }
                                    </p>
                                </div>

                                <div>
                                    <img src={ require('../images/turismo/reserva2.png') } />
                                    <p className="text-center grey-light-text">
                                        { cookie.get('i18next') === 'en' ? 'Guided hiking' : 'Senderismo guiado' }
                                    </p>
                                </div>

                                <div>
                                    <img src={ require('../images/turismo/reserva3.png') } />
                                    <p className="text-center grey-light-text">
                                        { cookie.get('i18next') === 'en' ? 'Interpretation Center' : 'Centro de interpretación' }
                                    </p>
                                </div>
                            </div>
							<p className="grey-light-text">{ t('tour_res_p4') }</p>
                        </div>

                    </div>
                    <img data-aos="zoom-in" src={ require('../images/turismo_2.png') } className="w-100 d-block image-turismo-banner" />
                </div>



                <div className="index-content-wrapper mb-0 pt-5 mobile-spacing-wrapper">
                    <div className="index-grid-content mb-6 bread-content two-block-wrapper align-items-start">
                        <div data-aos="fade-right" className="">
                            { cookie.get('i18next') === 'en' ?
                                <p className="top-titles">Tourist Circuit</p>
                                :
                                <p className="top-titles">Circuito Turístico</p>
                            }

                            { cookie.get('i18next') === 'en' ?
                                <h2 className="turismo-yerbatero-title mb-4">YERBATERO <br /> FOR A DAY</h2>
                                :
                                <h2 className="turismo-yerbatero-title mb-4">YERBATERO <br /> POR UN DÍA</h2>
                            }
                        </div>

						<div data-aos="fade-left" className="turismo-text-wrapper">
							<p className="grey-light-text">
								{ t('tour_ct_p1') }
							</p>

                            <div className="turismo-yerbatero-calendar">
                                <img className="d-block calendar-icon" src={ require('../images/turismo/calendar.png') } />

                                <div className="grey-light-text">
                                    <p className="m-0">
                                        <b>{ cookie.get('i18next') === 'en' ? 'OPENING HOURS' : 'HORARIO DE ATENCIÓN' }</b>
                                    </p>
                                    <p>{ cookie.get('i18next') === 'en' ? 'Thursday to Tuesday at 08:30' : 'Jueves a Martes a las 08:30' } Hs.</p>
                                </div>
                            </div>
						</div>
                    </div>
                </div>

                <div className="index-content-wrapper mb-0 pt-5 mobile-spacing-wrapper bg-light-grey">
                    <div className="index-grid-content mb-5 bread-content two-block-wrapper align-items-center">
                        <img data-aos="fade-right" className="d-block image-logo-aventura" src={ require('../images/turismo/logo_mono.png') } />
                        <h2 data-aos="fade-left" className="greenTitle title-tablet text-center block-light-spacing green-big-title-turismo mb-5" dangerouslySetInnerHTML={{ __html: t('tour_av_title') }} />
                    </div>

                    <div className="bread-content">
                        <h3 data-aos="fade-up" className="aventura-text text-center">{ t('tour_av_p1') }</h3>
                        <div className="aventura-block-wrapper">
                            <div data-aos="zoom-in">
                                <img src={ require('../images/turismo/aventura1.png') } />
                                <p className="grey-light-text text-center">{ t('tour_av_p2') }</p>
                            </div>

                            <div data-aos="zoom-in">
                                <img src={ require('../images/turismo/aventura2.png') } />
                                <p className="grey-light-text text-center">{ t('tour_av_p3') }</p>
                            </div>

                            <div data-aos="zoom-in">
                                <img src={ require('../images/turismo/aventura3.png') } />
                                <p className="grey-light-text text-center">{ t('tour_av_p5') }</p>
                            </div>

                            <div data-aos="zoom-in">
                                <img src={ require('../images/turismo/aventura4.png') } />
                                <p className="grey-light-text text-center">{ t('tour_av_p4') }</p>
                            </div>
                        </div>
                    </div>

                    <div className="simple-banner-wrapper mt-5 mb-0">
                        <img className="top-bg-design" src={ require('../images/corte_top.png') } />
                        <img className="bottom-bg-design" src={ require('../images/corte_bottom.png') } />
                        <img data-aos="zoom-in" src={ require('../images/turismo_3.png') } className="w-100 d-block" />
                    </div>

                </div>

                <div className="index-grid-content mb-6 mt-5 bread-content two-block-wrapper align-items-start">
                    <p data-aos="fade-right" className="grey-light-text">
                        { t('tour_hotel_p1') }
                    </p>

                    <p data-aos="fade-left" className="grey-light-text">
                        { t('tour_hotel_p2') }
                    </p>
                </div>

                <div data-aos="fade-up" className="bread-content">
                    <SplideImages images={hotel_slider_images} label="Hotel Encanto Rural" />
                </div>

				<div className="block-light-spacing bread-content mt-6">
					{ cookie.get('i18next') === 'en' ?
						<h2 data-aos="zoom-in" className="greenTitle text-center mt-5 mb-5">THE YERBA MATE ROUTE "KA'A RAPE"</h2>
						:
						<h2 data-aos="zoom-in" className="greenTitle text-center mt-5 mb-5">LA RUTA DE LA YERBA MATE "KA'A RAPE"</h2>
					}

					{ cookie.get('i18next') === 'en' ?
						<div data-aos="fade-up" className="grey-light-text text-center mobile-text-left">
							<p>It enchants all travelers, tourists and visitors who come to enjoy this experience.</p>
							<p>The tourist route "Ka'a Rape" (Yerba Mate Route), is an exciting tour, where nature, science, technology, customs and traditions are combined with the mystique that surrounds the yerba mate.</p>
							<p>This circuit, which covers 9 districts of Itapúa, enhances the cultural values and attractions of each municipality. The route follows Route 6 and starts in the city of Encarnación, passing through Capitán Miranda, Nueva Alborada, Jesús, Trinidad, Hohenau, Obligado, Bella Vista until reaching Capitán Meza.</p>
							<p>Within the Route we present Aventura Selecta where we offer 4 tourist experiences to enjoy throughout the entire year.</p>
						</div>
						:
						<div data-aos="fade-up" className="grey-light-text text-center mobile-text-left">
							<p>Enamora a todos los viajeros, turistas y visitantes que se acercan a disfrutar de esta experiencia.</p>
							<p>La ruta turística «Ka’a Rape» (Ruta de la Yerba Mate), es un paseo apasionante, donde se combinan la naturaleza, la ciencia, la tecnología, las costumbres y tradiciones con la mística que envuelve a la yerba mate.</p>
							<p>Este circuito que abarca a 9 distritos de Itapúa, potencia los valores culturales y atractivos de cada municipio. El recorrido sigue a la Ruta 6, e inicia en la ciudad de Encarnación, pasando por Capitán Miranda, Nueva Alborada, Jesús, Trinidad, Hohenau, Obligado, Bella Vista hasta llegar a Capitán Meza.</p>
							<p>Dentro de la Ruta les presentamos a Aventura Selecta donde ofrecemos 4 experiencias turísticas para disfrutarlas durante todo el año.</p>
						</div>
					}

					<img data-aos="zoom-in" className="mt-4 w-100 d-block" src={ require('../images/turismo/kaa_rape.png') } />
				</div>
			</div>
		</>
	)
}

export default Turismo;

import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

const SliderImages = (props) => {
	const [state, setState] = useState({
		index: 0,
		open: false
	})
	return (
		<>
				<Swiper
					style={{
						"--swiper-navigation-color": "#fff",
						"--swiper-pagination-color": "#fff",
					}}
					spaceBetween={10}
					navigation={true}
					modules={[FreeMode, Navigation, Thumbs]}
					className="pages-slider"
					>

					{ props.images.map((image, key) => (
						<SwiperSlide id={key}>
							<img src={ image } onClick={() => setState({ open: true, index: key })} />
						</SwiperSlide>
					))}
				</Swiper>

				{state.open && (
					<Lightbox
						mainSrc={props.images[state.index]}
						nextSrc={props.images[(state.index + 1) % props.images.length]}
						prevSrc={props.images[(state.index + props.images.length - 1) % props.images.length]}
						onCloseRequest={() => setState({ isOpen: false })}
						onMovePrevRequest={() =>
							setState(prev => ({ ...prev, index: (state.index + props.images.length - 1) % props.images.length }))
						}
						onMoveNextRequest={() =>
							setState(prev => ({ ...prev, index: (state.index + 1) % props.images.length }))
						}
					/>
				)}
		</>
	)
}

export default SliderImages

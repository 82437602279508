import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import TopVideo from '../videos/farmer.mp4';
import "react-18-image-lightbox/style.css";
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';
import SplideImages from '../components/SplideImages';
import lonelyLeaf from '../images/leaf.png';
import { useMediaQuery } from 'react-responsive';

const SelectaCompanies = () => {
	const { t } = useTranslation();
    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	document.title = cookie.get('i18next') === 'en' ? "Business - Selecta S.A" : "Empresas - Selecta S.A";

	const eno_images = [
		require("../images/eno_bronstrup/slider_1.png"),
		require("../images/eno_bronstrup/slider_2.png"),
		require("../images/eno_bronstrup/slider_3.jpg"),
		require("../images/eno_bronstrup/slider_4.jpg"),
		require("../images/eno_bronstrup/slider_5.jpg"),
		require("../images/eno_bronstrup/slider_6.jpg"),
		require("../images/eno_bronstrup/slider_7.jpg"),
		require("../images/eno_bronstrup/slider_8.jpg")
	]

	const mirador_images = [
		require('../images/selecta_empresas/mirador_1.jpg'),
		require('../images/selecta_empresas/mirador_2.jpg'),
		require('../images/selecta_empresas/mirador_3.jpg'),
		require('../images/selecta_empresas/mirador_4.jpg'),
		require('../images/selecta_empresas/mirador_5.jpg'),
		require('../images/selecta_empresas/mirador_6.jpg'),
		require('../images/selecta_empresas/mirador_7.jpg'),
		require('../images/selecta_empresas/mirador_9.jpg'),
		require('../images/selecta_empresas/mirador_10.jpg'),
		require('../images/selecta_empresas/mirador_11.jpg'),
		require('../images/selecta_empresas/mirador_12.jpg'),
		require('../images/selecta_empresas/mirador_13.jpg')
	]

	const farmer_images = [
		require('../images/selecta_empresas/farmer_1.jpg'),
		require('../images/selecta_empresas/farmer_2.jpg'),
		require('../images/selecta_empresas/farmer_3.jpg'),
		require('../images/selecta_empresas/farmer_4.jpg'),
		require('../images/selecta_empresas/farmer_5.jpg'),
		require('../images/selecta_empresas/farmer_6.jpg'),
		require('../images/selecta_empresas/farmer_7.jpg')
	]

	const bronweih = [
		require('../images/bronweih1.jpg'),
		require('../images/bronweih2.jpg'),
		require('../images/bronweih3.jpg')
	]

	const [state, setState] = useState({
		photoBron: 0,
		isOpenBron: false
	})

	return (
		<>
            { isMobileToTablet ?
                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/empresas_title_mobile_en.png') :
                        require('../images/empresas_title_mobile.png')
                    } className="imageSection" />
                :

                <img src={
                    cookie.get('i18next') == 'en' ?
                        require('../images/empresas_title_en.png') :
                        require('../images/empresas_title.png')
                    } className="imageSection" />
            }

			<div className="bodyContainer">
                <div className="index-content-wrapper mb-0">
					<img data-aos="fade-up" className="logo-section mb-6" src={ require('../images/ebsa_logo.jpg') } />
                    <div className="index-grid-content bread-content two-block-text-wrapper align-items-start">
						<div data-aos="fade-in" className="grey-light-text">
							<p>{ t('sb_eno_p1') }</p>
							<p>{ t('sb_eno_p2') }</p>
							<p>{ t('sb_eno_p3') }</p>
						</div>

						<div data-aos="fade-in" className="grey-light-text">
							<p>{ t('sb_eno_p4') }</p>
							<p>{ t('sb_eno_p5') }</p>
							<p>{ t('sb_eno_p6') }</p>
						</div>
                    </div>
                </div>

                <div className="banner-text-wrapper bg-empresas-text">
                    <img className="top-bg-design" src={ require('../images/corte_top.png') } />
                    <img className="bottom-bg-design" src={ require('../images/corte_bottom.png') } />

                    <a href="https://www.sgs.com/es-py" target="_blank">
                        <img data-aos="zoom-in" src={ require('../images/sgs_logo.png') } className="d-block sgs-logo" />
                    </a>
                    <p data-aos="fade-up" className="text-center color-white sgs-text">{ t('sb_eno_p7') }</p>
                </div>

                <div data-aos="fade-up" className="bread-content">
                    <SplideImages images={eno_images} label="Yerba Mate" />
                </div>

                <div className="index-content-wrapper mt-7 mb-0">
                    <img data-aos="fade-right" className="lonely-left-leaf selecta-empresas-hoja-bottom" src={ lonelyLeaf } />
                    <img data-aos="fade-left" className="lonely-right-leaf selecta-empresas-hoja-right" src={ lonelyLeaf } />

                    <div className="index-grid-content bread-content two-block-text-wrapper align-items-center">
                        <div data-aos="fade-right">

                            <h2 className="greenTitle mb-5">
                                { t('sb_policies_title') }
							</h2>
                            <div className="grey-light-text">
                                <p>{ t('sb_policy_desc1') }</p>
                                <p><b>{ t('sb_policy_desc2') }</b></p>
                            </div>
                        </div>

                        <div data-aos="fade-left" className="column-policies grey-light-text">
                            <div className="column-policy">
                                <p>1</p>
                                <div>
                                    { cookie.get('i18next') === 'en' ?
                                        <h3> Applicable requirements</h3>
                                        :
                                        <h3> Requisitos aplicables</h3>
                                    }
                                <p>{ t('sb_policy_block1') }</p>
                                </div>
                            </div>

                            <div className="column-policy">
                                <p>2</p>
                                <div>
                                    { cookie.get('i18next') === 'en' ?
                                        <h3>Qualified people</h3>
                                        :
                                        <h3>Personas Capacitadas</h3>
                                    }
                                <p>{ t('sb_policy_block2') }</p>
                                </div>
                            </div>

                            <div className="column-policy">
                                <p>3</p>
                                <div>
                                    { cookie.get('i18next') === 'en' ?
                                        <h3>Improve processes</h3>
                                        :
                                        <h3>Mejorar los procesos</h3>
                                    }
                                <p>{ t('sb_policy_block3') }</p>
                                </div>
                            </div>

                            <div className="column-policy">
                                <p>4</p>
                                <div>
                                    { cookie.get('i18next') === 'en' ?
                                        <h3>Contribute</h3>
                                        :
                                        <h3>Contribuir</h3>
                                    }
                                <p>{ t('sb_policy_block4') }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="index-content-wrapper mb-0">
					<img data-aos="zoom-in" className="logo-section" src={ require('../images/mirador_logo.jpeg') } />
                    <div className="index-grid-content bread-content mt-5 two-block-text-wrapper align-items-start">
                        <div data-aos="fade-right" className="grey-light-text">
                            <p>{ t('sb_mirador_p1') }</p>
                        </div>

                        <div data-aos="fade-left" className="grey-light-text">
                            <p>{ t('sb_mirador_p2') }</p>
                        </div>
                    </div>
                </div>

                <div className="banner-text-wrapper bg-mirador-text">
                    <img className="top-bg-design" src={ require('../images/corte_top.png') } />
                    <img className="bottom-bg-design" src={ require('../images/corte_bottom.png') } />
                    { cookie.get('i18next') === 'en' ?
                        <h2 data="zoom-in" className="text-center color-white">It has</h2>
                        :
                        <h2 data="zoom-in" className="text-center color-white">Cuenta con</h2>
                    }

                    <div className="mirador-flex-content-wrapper">
                        <div data-aos="fade-down" className="mirador-content color-white">
                            <h2>2.000 ha</h2>
                            { cookie.get('i18next') === 'en' ?
                                <p>Agriculture Area</p>
                                :
                                <p>Área de Agricultura</p>
                            }
                        </div>

                        <div data-aos="fade-down" className="mirador-content color-white">
                            <h2>300 ha</h2>
                            { cookie.get('i18next') === 'en' ?
                                <p>Yerba mate plantations</p>
                                :
                                <p>Plantaciones de Yerba Mate</p>
                            }
                        </div>

                        <div data-aos="fade-down" className="mirador-content color-white">
                            <h2>50 ha</h2>
                            { cookie.get('i18next') === 'en' ?
                                <p>Mandioca plantations</p>
                                :
                                <p>Plantaciones de Mandioca</p>
                            }
                        </div>

                        <div data-aos="fade-down" className="mirador-content color-white">
                            <h2>71 ha</h2>
                            { cookie.get('i18next') === 'en' ?
                                <p>Forestry Area</p>
                                :
                                <p>Área Forestal</p>
                            }
                        </div>
                        <div data-aos="fade-down" className="mirador-content color-white">
                            <h2>1.800</h2>
                            { cookie.get('i18next') === 'en' ?
                                <p>Cattle head</p>
                                :
                                <p>Cabeza de ganado Vacuno</p>
                            }
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" className="bread-content">
                    <SplideImages images={mirador_images} label="Yerba Mate" />
                </div>

                <div className="index-content-wrapper mb-0">
					<img data-aos="zoom-in" className="logo-section farmer-logo mb-5" src={ require('../images/farmer_logo.png') } />
                    <div className="index-grid-content bread-content two-block-text-wrapper align-items-start">
                        <div data-aos="fade-right" className="grey-light-text">
                            <p>{ t('sb_farmer_p1') }</p>
                            <p>{ t('sb_farmer_p2') }</p>
                            <p>{ t('sb_farmer_p3') }</p>
                        </div>

                        <div data-aos="fade-left" className="grey-light-text">
                            <p>{ t('sb_farmer_p4') }</p>
                            <p>{ t('sb_farmer_p5') }</p>
                            <p>{ t('sb_farmer_p6') }</p>
                        </div>
                    </div>
                </div>

                <div data-aos="zoom-in" className="index-content-wrapper mt-6 mb-6">
                    <img className="top-bg-design z-1" src={ require('../images/corte_top.png') } />
                    <img className="bottom-bg-design z-1" src={ require('../images/corte_bottom.png') } />
                    <div className="block-spacing video-block mt-0">
						<ReactPlayer
							className='react-player'
							url={[TopVideo,'mp4']}
							muted={false}
							width="100%"
							height="100%"
							config= {{
								file: {
									attributes: {
										controls: true,
										autopictureinpicture: true,
										poster: require('../videos/farmer_poster.png'),
									}
								}
							}}
						/>
                    </div>
                </div>

                <div data-aos="fade-up" className="bread-content">
                    <SplideImages images={farmer_images} label="Yerba Mate" />
                </div>

                <div className="index-content-wrapper mb-0">
                    <div className="index-grid-content bread-content two-block-wrapper only-one-block align-items-start">
                        <img data-aos="zoom-in" src={ require('../images/bronweih_logo.png') } className="bronweih-logo d-block mx-auto" />
                        <div data-aos="fade-up" className="grey-light-text">
                            <p>{ t('sb_bron_p1') }</p>
                            <p>{ t('sb_bron_p2') }</p>
                            <p>{ t('sb_bron_p3') }</p>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" className="bread-content">
                    <SplideImages images={bronweih} label="Yerba Mate" />
                </div>
			</div>
		</>
	)
}

export default SelectaCompanies

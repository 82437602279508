import { React, useState, useReducer, useEffect } from 'react';
import productImage from '../images/arroz.png';
import TopProductGroup from '../components/TopProductGroup';
import ProductFilters from '../components/ProductFilters';
import ProductGrids from '../components/ProductGrids';
import config from '../config/config.json';
import axios from 'axios';
import Loading from '../components/Loading';
import { useNavigate, useLocation } from 'react-router-dom';
import cookie from 'js-cookie';
import { useMediaQuery } from 'react-responsive';

const Product = () => {
    let groupName = decodeURIComponent(window.location.href.split('/')[6]);
    let tradeType = window.location.href.split('/')[4];
    let navigate = useNavigate();
    let location = useLocation();

    document.title = groupName + " - Selecta S.A";

    const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
    const [isLoading, setIsLoading] = useState(false);
    const [event, updateEvent] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, { 
        selectedFamily: '', 
        selectedGroup: '', 
        tradeType: '', 
        groupId: parseInt(window.location.href.split('/')[5]) 
    });

    const [groups, setGroups] = useState([]);
    const [families, setFamilies] = useState([]);
    const [featured, setFeatured] = useState(null);
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        //Clean up states first.
        updateEvent({ selectedGroup: '', selectedFamily: '' });
        let splitted = location.pathname.split('/');

        //Update tradeType state.
        updateEvent({ tradeType: splitted[2] });

        //Update group state.
        updateEvent({ groupId: splitted[3] });
        updateEvent({ selectedGroup: splitted[3] });

        //Check if family is present.
        if(splitted[5] !== undefined) {
            updateEvent({ selectedFamily: splitted[5] });
        }
    }, [location]);

    useEffect(() => {
        setIsLoading(true);
        let ignore = false;

        function fetchData() {
            axios
                .get(config.SERVER_URL + config.GET_ALL_PRODUCTS_GROUPS)
                .then((response) => {
                    if (!ignore) {

                        response.data.data.map((group) => {
                            if(group.id === event.selectedGroup) {
                            let featuredProducts = [];
                                let familiesPerTradeType = [];

                                if(event.tradeType === 'cn') {
                                    group.familias?.map(family => {
                                        family.productos.filter(p => p.comercioNac === true).map(product => {
                                            familiesPerTradeType.push(family);
                                        });
                                    });

                                    let uniqueFamiliesPerTradeType = [...new Set(familiesPerTradeType)];
                                    setFamilies(uniqueFamiliesPerTradeType);
                                }

                                else {
                                    group.familias?.map(family => {
                                        family.productos.filter(p => p.comercioExt === true).map(product => {
                                            familiesPerTradeType.push(family);
                                        });
                                    });

                                    let uniqueFamiliesPerTradeType = [...new Set(familiesPerTradeType)];
                                    setFamilies(uniqueFamiliesPerTradeType);
                                }

                                // Get one featured image.
                                if(event.tradeType == 'cn') {
                                    group.familias?.map(family => {
                                        family.productos.filter(p => p.destacado === true && p.comercioNac === true)
                                            .map(product => {
                                                featuredProducts.push(product);
                                        });
                                    });

                                    let randomFeatureProduct = Math.floor(Math.random() * featuredProducts.length);
                                    setFeatured(featuredProducts[randomFeatureProduct]);
                                }
                                else {
                                    group.familias?.map(family => {
                                        family.productos.filter(p => p.destacado === true && p.comercioExt === true)
                                            .map(product => {
                                                featuredProducts.push(product);
                                        });
                                    });

                                    let randomFeatureProduct = Math.floor(Math.random() * featuredProducts.length);
                                    setFeatured(featuredProducts[randomFeatureProduct]);
                                }

                                setGroups(group);
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            .then(() => {
                setIsLoading(false)
            })
        }

        fetchData();
        navigateUrl();

        return () => {
            ignore = true;
        }
        // If there are changes in selectedGroup, family and tradetype, it will re-render and show it.
        }, [event.selectedGroup, event.selectedFamily, event.tradeType]);


    // Group Banner API
    useEffect(() => {
        let ignore = false;
        axios.get(config.SERVER_URL + config.GET_GROUP_BANNER + event.groupId)
            .then((response) => {
                if(!ignore) {
                    let bannerData = response.data.data;
                    setBanners(bannerData);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
            })

        return () => {
            ignore = true;
        }

    }, [event.groupId]);


    const checkUrl = () => {
        let url = window.location.href.split('/');
        let currentPage = "";
        currentPage = "/"+url[3]+"/"+url[4]+"/"+url[5]+"/"+url[6];

        return currentPage;
    }

    const changeProducts = (family) => {
        updateEvent({ selectedFamily: family });
    }

    const navigateUrl = () => {
        //Check if only group exists in the URL
        if(event.selectedFamily == '' && event.selectedGroup !== '') {
            navigate(checkUrl());
        }

        //Check if both group and family exists in the URL
        if(event.selectedFamily !== '' && event.selectedGroup !== '') {
            navigate(checkUrl()+`/${event.selectedFamily}`);
        }
    }

    const productCount = () => {
        let count = 0;
        if(groups) {
            if(groups.familias) {
                if(event.selectedFamily !== '') {
                    groups.familias
                        .filter(f => f.id === parseInt(event.selectedFamily))
                        .map(family => {
                            if(event.tradeType === "cn") {
                                family.productos.filter(p => p.comercioNac === true).map(product => {
                                    count = count + 1;
                                });
                            }
                            else {
                                family.productos.filter(p => p.comercioExt === true).map(product => {
                                    count = count + 1;
                                });
                            }
                        });
                }

                else {
                    groups.familias
                        .map(family => {
                            if(event.tradeType === "cn") {
                                family.productos.filter(p => p.comercioNac === true).map(product => {
                                    count = count + 1;
                                });
                            }
                            else {
                                family.productos.filter(p => p.comercioExt === true).map(product => {
                                    count = count + 1;
                                });
                            }
                        });
                }
            }
        }

        return count;
    }

    return (
        <>
            { banners ?
                banners?.length > 0 && (
                    <>
                        { banners[0].files?.map(banner => (
                            isMobileToTablet ?
                                banner.bannerFor === "Mobile" ?
                                    <img 
                                        src={`https://dev-back-selecta.creadores.io/uploads/${banner.uploadResult?.storedFileName}`}
                                        className="w-100 d-block mb-3" />
                                    : ""
                                :
                                banner.bannerFor === "Desktop" ?
                                    <img 
                                        src={`https://dev-back-selecta.creadores.io/uploads/${banner.uploadResult?.storedFileName}`}
                                        className="w-100 d-block mb-3" />
                                    : ""
                        ))}
                    </>
                )
                : null
            }

            <div className={ banners?.length > 0 ? "bodyContainer bread-content mt-0" : "bodyContainer bread-content mt-7" }>

                { false && (
                    <h1 className="greenTitle text-center">{ groupName }</h1>
                )}

                { false && (
                    <TopProductGroup
                        title={ groupName }
                        tradeType={ event.tradeType }
                        featuredProduct={ featured }
                        image={ productImage } />
                )}

                <ProductFilters
                    families={ families }
                    selectedFamily={ event.selectedFamily }
                    changeProducts={ changeProducts }
                    totalProducts = { productCount }
                />

                { !isLoading ?
                    <>

                        { event.selectedFamily !== '' ?
                            <div className="productGridWrapper mt-4">
                                { event.tradeType === "cn" ?
                                    groups.familias?.filter(f => f.id === parseInt(event.selectedFamily))
                                    .map(family => (
                                        family.productos?.filter(product => product.comercioNac === true).map((product, key) => (
                                            <ProductGrids
                                                key={key}
                                                product={ product }
                                                tradeType={ tradeType }
                                                groupName={ groupName }
                                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs } />
                                        ))
                                    ))
                                    :
                                    groups.familias?.filter(f => f.id === parseInt(event.selectedFamily))
                                    .map(family => (
                                        family.productos?.filter(product => product.comercioExt === true).map((product, key) => (
                                            <ProductGrids
                                                key={key}
                                                product={ product }
                                                tradeType={ tradeType }
                                                groupName={ groupName }
                                                familyName={ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs } />
                                        ))
                                    ))

                                }
                            </div>
                            :
                            <div className="productGridWrapper">
                                { event.tradeType == "cn" ?
                                    groups.familias?.map((family) => (
                                        family.productos.filter(product => product.comercioNac == true).map((product) => (
                                            <ProductGrids
                                                product={ product }
                                                tradeType={ tradeType }
                                                groupName={ groupName }
                                                familyName={ cookie.get('i18next') == 'en' ? family.nameEn : family.nameEs } />
                                        ))
                                    ))

                                    :
                                    groups.familias?.map((family) => (
                                        family.productos.filter(product => product.comercioExt == true).map((product) => (
                                            <ProductGrids
                                                product={ product }
                                                tradeType={ tradeType }
                                                groupName={ groupName }
                                                familyName={ cookie.get('i18next') == 'en' ? family.nameEn : family.nameEs } />
                                        ))
                                    ))
                                }

                            </div>
                        }
                    </>
                    :
                    <Loading />
                }

            </div>
        </>
    )
}

export default Product;

import React, { useState } from "react";
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import Spinner from 'react-bootstrap/Spinner';
import AlertMessage from "../components/AlertMessage";
import AlertToastMessages from "../components/AlertToastMessages";

function ChangePassword() {
	document.title = cookie.get('i18next') == 'en' ?
		"Change your password - Selecta S.A." : "Cambiar contraseña - Selecta S.A.";

	const userId = window.location.href.split('/')[4];

	const initialState = {
		password: { data: '', error: '' },
		confirmPassword: { data: '', error: '' }
	}

	const [showToast, setShowToast] = useState(false);
	const [inputs, setInputs] = useState(initialState);

	const [variant, setVariant] = useState(null);
	const [message, setMessage] = useState(null);
	const [alertShow, setAlertShow] = useState(false);
	const [checking, setChecking] = useState(false);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		if(value !== '') {
			setInputs(prevState => { return { ...prevState, [name]: { error: '' }}});
		}

		setInputs({ ...inputs, [name] : { data: value }});
	}

	const handleCloseAlert = (show) => {
		setAlertShow(show);
	}

	const validateFields = () => {
		let ready = false;
		let errorFound = 0;

		if(!inputs.password.data) {
			setInputs(prevState => { return { ...prevState, password: {
				error: cookie.get('i18next') === 'en' ? "Password field is required" : "Debes completar el campo de Contraseña"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.password.data || inputs.password.data.length < 8) {
			setInputs(prevState => { return { ...prevState, password: {
				error: cookie.get('i18next') === 'en' ? "The password must contain at least 8 characters and at least one capital letter." : "La contraseña debe tener al menos 8 caracteres y al menos una letra en mayúscula."
			}}});
			errorFound++;
			setChecking(false);
		}

		if(!inputs.confirmPassword.data) {
			setInputs(prevState => { return { ...prevState, confirmPassword: {
				error: cookie.get('i18next') === 'en' ? "Confirm password is required" : "Debes completar el campo de confirmación de contraseña"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(inputs.password.data !== inputs.confirmPassword.data) {
			setInputs(prevState => { return { ...prevState, confirmPassword: {
				error: cookie.get('i18next') === 'en' ? "Confirm password doesn't match with submitted password" : "La confirmación de contraseña es distinta a la contraseña ingresada"
			}}});
			errorFound++;
			setChecking(false);
		}

		if(errorFound <= 0) {
			ready = true;
		}

		return ready;
	}

	function handleSuccess() {
		window.location.href = "/perfil";
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		let ignore = false;
		setChecking(true);

		if(validateFields()) {
			axios.put(config.SERVER_URL + config.PUT_CHANGE_PASSWORD + userId, {
				userId: userId,
				password: inputs.password.data
				})
				.then((response) => {
					if (!ignore) {
						console.log(response);
						if(response.data.status === "error") {
							setVariant("danger");
							setMessage(response.data.message);
							setAlertShow(true);
							setChecking(false);
						}

						if(response.data.status === "success") {
							setShowToast(true);
							setChecking(false);
							setTimeout(handleSuccess, 2500);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					ignore = true;
			})
		}
	}

	return (
		<>
			{ alertShow && (
				<AlertMessage
					variant={ variant }
					message={ message }
					showMessage={ alertShow }
					handleShow={ handleCloseAlert } />
			)}

			<AlertToastMessages
				show={showToast}
				setShow={setShowToast}
				message={ cookie.get('i18next') == 'en' ? "Password changed successfully!" : "Contraseña cambiada con éxito!" }/>

			<section className="userSection">
				{ cookie.get('i18next') == 'en' ?
					<>
					<h1 data-aos="fade-down">Change your Password</h1>
						<p data-aos="fade-down">Complete the following fields to change your password.
							<strong> For secutiry reasons, do not share your password with anyone.</strong>
						</p>
					</>
					:
					<>
						<h1 data-aos="fade-down">Cambiar contraseña</h1>
						<p data-aos="fade-down">Completa los siguientes campos para cambiar tu contraseña.
							<strong> Por seguridad, no compartas con nadie tu contraseña.</strong>
						</p>
					</>
				}

				<form
                    data-aos="fade-up"
					className="formUser"
					onSubmit={(e) => { handleSubmit(e) }}>

					<div className="mb-3">
						<label for="loginPassword" className="form-label">
							{ cookie.get('i18next') === 'en' ? "New password *" : "Nueva contraseña *" }
						</label>

						<input
							type="password"
							className="form-control"
							id="loginPassword"
							name="password"
							value={ inputs.password.data || '' }
							onChange={ handleInputChange } />

						<span className="span-error-message">{ inputs.password.error !== '' ? inputs.password.error : '' }</span>
						<i>
							{ cookie.get('i18next') === 'en' ?
								"The password must contain at least 8 characters and at least one capital letter."
								:
								"La contraseña debe contener al menos 8 caracteres y al menos una letra en mayúscula."
							}
						</i>
					</div>

					<div className="mb-3">
						<label for="loginConfirmPassword" className="form-label">
							{ cookie.get('i18next') === 'en' ? "Confirm new password *" : "Confirmar nueva contraseña *" }
						</label>
						<input
							type="password"
							className="form-control"
							id="loginConfirmPassword"
							name="confirmPassword"
							value={ inputs.confirmPassword.data || '' }
							onChange={ handleInputChange} />

						<span className="span-error-message">{ inputs.confirmPassword.error !== '' ? inputs.confirmPassword.error : '' }</span>
					</div>

					<button
						type="submit"
						className="btn btnFormLogin"
						disabled={ !checking ? false : true }>
						{ !checking ?
							cookie.get('i18next') === 'en' ? "Change password" : "Cambiar contraseña"
							:
							<>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									style={{ marginRight: "10px" }}
								/>
								{ cookie.get('i18next') === 'en' ? "Verifying..." : " Verificando..."  }
						</>
						}
					</button>
				</form>
			</section>
			</>
	)
}

export default ChangePassword;

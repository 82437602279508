import React from 'react';
import { NavLink } from 'react-router-dom';
import productImage from '../images/placeholder.png';
import cookie from 'js-cookie';

const ProductGrids = ({ product, tradeType, groupName, familyName }) => {
	const getImageProduct = (productId) => {
		let imageUrl = ""
		if(product !== undefined) {
			if(product.files?.length > 0) {
				product.files?.map(file => {
					if(file.fileType == "MainImage") {
						if(file.productoId == productId) {
							imageUrl = "https://dev-back-selecta.creadores.io/uploads/"+file.uploadResult.storedFileName
						}
					}
				})
			}
		}
		return imageUrl
	}

	const getVariety = () => {
		let variety = "";
		if(product.presentaciones.length > 0) {
			if(product.presentaciones[0].variedades.length > 0) {
				if(cookie.get('i18next') == 'en') {
					variety = product.presentaciones[0].variedades[0].nameEn;
				} else {
					variety = product.presentaciones[0].variedades[0].nameEs;
				}
			}
		}

		return variety;
	}

	function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

	function PascalCase(string) {
		const lowerCase = string.toLowerCase();
		const finalSentence = lowerCase.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
		return finalSentence;
	}

	return (
		<>
			<div data-aos="fade-down" className="product">
				<NavLink
					className="showProductButton" to={ tradeType == "cn" ? "/producto/cn/"+product.id : "/producto/ft/"+product.id }>
					<img src={ getImageProduct(product.id) !== "" ? getImageProduct(product.id) : productImage } />
				</NavLink>

				<NavLink
					className="showProductButton" to={ tradeType == "cn" ? "/producto/cn/"+product.id : "/producto/ft/"+product.id }>
					<h3>{ toTitleCase(cookie.get('i18next') == 'en' ? product.nameEn : product.nameEs) }</h3>
				</NavLink>

					<p>{ PascalCase(familyName) } - { PascalCase(getVariety()) }</p>

                { false && (
                    <div className="showProductWrapper">
                        <NavLink
                            className="showProductButton" to={ tradeType == "cn" ? "/producto/cn/"+product.id : "/producto/ft/"+product.id }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                            { cookie.get('i18next') == 'en' ? "Show product": "Ver producto" }
                        </NavLink>
                    </div>
                )}
			</div>
		</>
	)
}

export default ProductGrids;

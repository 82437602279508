import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';

const ProductFilters = ({ families, selectedFamily, changeProducts, totalProducts }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="productFilters mt-5">
				<Row data-aos="fade-right" className="leftFilterRow">
					{ families ?
						<Col>
							<FloatingLabel controlId="floatingSelect" label={ t('product_filter_families') }>
								<Form.Select aria-label="Floating label select example" onChange={ (e) => changeProducts(e.target.value) }>
									<option value="">{ t('product_filter_select') }</option>
									{ families.map((family) => (
										family.productos.length > 0 ?
											<option value={family.id} selected={ selectedFamily === family.id ? 'selected' : false }>{ cookie.get('i18next') === 'en' ? family.nameEn : family.nameEs }</option>
										: null
									))}
								</Form.Select>
							</FloatingLabel>
						</Col>
					: null
					}
				</Row>

				<Row data-aos="fade-left" className="rightFilterRow">

					<Col className="align-right">
						{ cookie.get('i18next') === 'en' ?
							<p className="m-0">{ totalProducts() } products</p>
							:
							<p className="m-0">{ totalProducts() } productos</p>
						}
					</Col>
				</Row>
			</div>
		</>
	)
}

export default ProductFilters;

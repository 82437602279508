import React, { useState, useEffect } from "react";
import NewsGrid from '../components/NewsGrid';
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import Loading from '../components/Loading';

const News = () => {
	document.title = cookie.get('i18next') === "en" ?
		"National and Foreign Trade News - Selecta S.A"
		: "Noticias Nacionales y Comercio Exterior - Selecta S.A";

	const [loading, setLoading] = useState(false);
	const [news, setNews] = useState(null);

	useEffect(() => {
		setLoading(true);
		let ignore = false;

		async function getNews() {
			await axios
			.get(config.SERVER_URL + config.GET_ALL_NEWS)
			.then((response) => {
				if(!ignore) {
					//let filteredData = response.data.data.filter(n => n.type === "Comercio Nacional");
					setNews(response.data.data)
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setLoading(false);
			});
		}

		getNews();

		return () => {
			ignore = true;
		}
	}, []);

	return (
		<>
			<img data-aos="zoom-in" src= {
                require('../images/banner_noticias.png')
				} className="imageSection" />

			<div className="bodyContainer bread-content mt-7 mb-5">
				<h2 data-aos="fade-up" className="greenTitle mt-7 mb-5">{ cookie.get('i18next') === "en" ? "NATIONAL NEWS" : "NOTICIAS NACIONALES" }</h2>
				{ !loading ?
					news?.filter(n => n.type === "Comercio Nacional").length > 0 ?
						<div className="news-grid-wrapper">
							<NewsGrid type={"cn"} news={ news } />
						</div>
					: <p data-aos="fade-up" className="grey-text mt-5 mb-5">No hay noticias cargadas actualmente.</p>
					:
					<Loading />
				}

				<h2 data-aos="fade-up" className="greenTitle mt-7 mb-5">{ cookie.get('i18next') === "en" ? "FOREIGN TRADE NEWS" : "NOTICIAS COMERCIO EXTERIOR" }</h2>

				{ !loading ?
					news?.filter(n=> n.type === "Comercio Exterior").length > 0 ?
						<div className="news-grid-wrapper">
							<NewsGrid type={"ft"} news={ news } />
						</div>
					: <p data-aos="fade-up" className="grey-text mt-5 mb-5">No hay noticias cargadas actualmente.</p>
					:
					<Loading />
				}
			</div>
		</>
	)
}

export default News;

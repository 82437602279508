import React from "react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import cookie from 'js-cookie';

function RecipeFilter({ filterData, filterChange, q, setQ }) {
	console.log(`FilterData: ${filterData}`);
	return (

		<div className="block-light-spacing mb-5 filter-recipes-wrapper">
			<FloatingLabel className="filter-product-recipe" controlId="floatingSelect"
				label={ cookie.get('i18next') == 'en' ? "Product" : "Producto" }>

				<Form.Select
					aria-label="Seleccionar producto para receta"
					onChange={e => filterChange(e.target.value) }
					>
					<option value="All">{ cookie.get('i18next') == 'en' ? "Select..." : "Seleccionar..." }</option>

					{ filterData.filter(d => d !== "All").map(data => (
						<option value={ data }>
							{ data }
						</option>
					))}

				</Form.Select>
			</FloatingLabel>

			<InputGroup className="search-recipe-group">
				<Form.Control
					value={ q }
					type="search"
					onChange={ (e) => { setQ(e.target.value) }}
					placeHolder={ cookie.get('i18next') == 'en' ? "Search by products..." : "Buscar por producto..." }
					aria-label="Filtrar productos" />

				<InputGroup.Text>
					<FontAwesomeIcon icon={ faMagnifyingGlass } />
				</InputGroup.Text>
			</InputGroup>
		</div>
	)
}

export default RecipeFilter;

import React, { useState, useEffect } from 'react';
import RecipeGrids from '../components/RecipeGrids';
import cookie from 'js-cookie';
import axios from 'axios';
import config from '../config/config.json';
import Loading from '../components/Loading';
import RecipeFilter from '../components/RecipeFilter';
import { useTranslation } from 'react-i18next';

const Recetas = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const [recipes, setRecipes] = useState([]);
	const [q, setQ] = useState("");
	const [searchParam] = useState(["titleEn", "titleEs", "nameEs", "nameEn"]);

	const [filterEn, setFilterEn] = useState(['All']);
	const [filterEs, setFilterEs] = useState(['All']);
	const [filtered, setFiltered] = useState("All");

	document.title = cookie.get('i18next') === 'en' ? "Recipes - Selecta S.A." : "Recetas - Selecta S.A.";

	useEffect(() => {
		setLoading(true);
		let ignore = false;

		async function getRecipes() {
			await axios
			.get(config.SERVER_URL + config.GET_ALL_RECIPES)
			.then((response) => {
				// handle success
				if(!ignore) {
					let productsEsArray = [];
					let productsEnArray = [];

					setRecipes(response.data.data);

					productsEsArray.push("All");
					productsEnArray.push("All");

					response.data.data.map(recipe => {
						let productEs = recipe.producto?.nameEs;
						let productEn = recipe.producto?.nameEn;

						productsEsArray.push(productEs);
						productsEnArray.push(productEn);
					});

					console.log(productsEnArray);
					console.log(productsEsArray);

					let uniqueValuesEn = [...new Set(productsEnArray)];
					setFilterEn(uniqueValuesEn);

					let uniqueValuesEs = [...new Set(productsEsArray)];
					setFilterEs(uniqueValuesEs);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				setLoading(false);
			});
		}

		getRecipes();

		return () => {
			ignore = true;
		}
	}, []);


	useEffect(() => {
		console.log(filterEs);
		console.log(filterEn);

	}, [filterEs, filterEn]);

	const data = Object.values(recipes);

	function search(items) {
		return items.filter((item) => {
			console.log(`filtered; ${filtered}`);

			if (item.producto?.nameEs === filtered || item.producto?.nameEn === filtered) {
				let searchParamConfirm = searchParam.some((newItem) => {
					if(newItem === "nameEs" || newItem === "nameEn") {
						return (
							item.producto[newItem]
								.toString()
								.toLowerCase()
								.indexOf(q.toLowerCase()) > -1
						);
					}
					else {
						return (
							item[newItem]
								.toString()
								.toLowerCase()
								.indexOf(q.toLowerCase()) > -1
						);
					}
				});

				if(searchParamConfirm) {
					return searchParamConfirm;
				}
			}

			else if(filtered == "All") {

				let searchParamConfirm = searchParam.some((newItem) => {
					if(newItem === "nameEs" || newItem === "nameEn") {
						return (
							item.producto[newItem]
								.toString()
								.toLowerCase()
								.indexOf(q.toLowerCase()) > -1
						);
					}
					else {
						return (
							item[newItem]
								.toString()
								.toLowerCase()
								.indexOf(q.toLowerCase()) > -1
						);
					}
				});

				if(searchParamConfirm) {
					return searchParamConfirm;
				}
			}
		});
	}

	return (
		<>
            <img data-aos="fade-up" src={ require('../images/receta_banner.png') } className="w-100 d-block" />
			<div className="bodyContainer bread-content">
                <h2 data-aos="fade-right" className="greenTitle mt-4 mb-4 title-with-border">
                    { cookie.get('i18next') === 'en' ? "CATALOG RECIPES" : "CATÁLOGO DE RECETAS" }
                </h2>
                { cookie.get('i18next') === 'en' ?
                    <h3 data-aos="fade-right" className="grey-text fst-italic">COOKING WITH AGRICULTURAL PRODUCTS</h3>
                    :
                    <h3 data-aos="fade-right" className="grey-text fst-italic">COCINA CON PRODUCTOS DEL AGRO</h3>
                }

				{ !loading ?
					recipes && (
						<>
							<RecipeFilter
								filterData={ cookie.get('i18next') == 'en' ? filterEn : filterEs }
								filterChange={ setFiltered }
								q={ q }
								setQ={ setQ }
							/>


							<div className="recipe-grid-wrapper">
								{ search(data).map((recipe, key) => (
									<RecipeGrids key={key} recipe={ recipe } />
								))}
							</div>
						</>
					)
					:
					<Loading />
				}
			</div>
		</>
	)
}

export default Recetas;

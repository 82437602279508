import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import cookie from 'js-cookie';

const RecipeGrids = ({ recipe }) => {
    return(
        <>
            { recipe && (
                <div data-aos="fade-down" className="recipe">
                    <NavLink className="link-img-recipe d-block mb-2" to={`/recetas/${recipe.id}`}>
                        <img
                            className="w-100 d-block image-grid"
                            src={ `https://dev-back-selecta.creadores.io/uploads/${recipe.image?.storedFileName}` }
                        />
                    </NavLink>

                    <NavLink to={`/recetas/${recipe.id}`}>
                        <h3>{ cookie.get('i18next') == 'en' ? recipe.titleEn : recipe.titleEs }</h3>
                    </NavLink>

                    { cookie.get('i18next') === 'en' ?
                        <p><b>Product:</b> { recipe.producto?.nameEn }</p>
                        :
                        <p><b>Producto:</b> { recipe.producto?.nameEs }</p>
                    }

                    <div className="show-more-recipe-wrapper">
                        <Link className="show-recipe-button" to={`/recetas/${recipe.id}`} reloadDocument>
                            { cookie.get('i18next') == 'en' ? "Show more" : "Ver más" }
                        </Link>
                    </div>
                </div>
            )}
        </>
    )
}

export default RecipeGrids;
